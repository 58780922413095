import '../styles/globals.css'
import { appWithTranslation } from 'next-i18next';


 function MyApp({ Component, pageProps }) {
    // Use the layout defined at the page level, if available
    const getLayout = Component.getLayout || ((page) => page)
  
    return getLayout(<Component {...pageProps} />)
  }
export default appWithTranslation(MyApp)
